<template>
   <div class="col-12 mb-1" ref="fatura">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-sm text-center text-sm-start">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-17 mb-0 limitador-1 text-uppercase">R$ {{ parseFloat(fatura.total).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</h1>
                        <p class="font-10 mb-2 mb-sm-0 mb-md-2 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ fatura.id }}</span>
                           <span class="text-green ms-3" v-if="fatura.pago"><i class="far fa-check font-9 me-1"></i>Pago</span>
                           <span class="text-yellow ms-3" v-else><i class="far fa-exclamation-triangle font-8 me-1"></i>Pendente</span>
                        </p>
                     </div>
                     <div class="col d-none d-md-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-calendar font-10 me-1" :class="fatura.pago ? 'text-green' : 'text-yellow'"></i><strong class="subtitle me-1 d-none d-lg-inline"> Período:</strong>
                           <span>{{ String(fatura.inicio.dayOfMonth).padStart(2, '0') +'/'+ String(fatura.inicio.monthValue).padStart(2, '0') +'/'+ fatura.inicio.year }} até</span>
                           <span> {{ String(fatura.fim.dayOfMonth).padStart(2, '0') +'/'+ String(fatura.fim.monthValue).padStart(2, '0') +'/'+ fatura.fim.year }}</span>
                        </p>
                     </div>
                     <div class="col-6 d-none d-xxl-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-info-circle font-10 me-1" :class="fatura.pago ? 'text-green' : 'text-yellow'"></i><strong class="subtitle me-1 d-none d-lg-inline"> Obs.:</strong>
                           <span> {{ fatura.observacao == null || String(fatura.observacao).trim().length == 0 ? 'Sem observação' : fatura.observacao }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="wpx-150 font-15 text-center mx-auto mx-sm-0">
                  <span>{{ String(fatura.vencimento.dayOfMonth).padStart(2, '0') +'/'+ String(fatura.vencimento.monthValue).padStart(2, '0') +'/'+ fatura.vencimento.year }}</span>
                  <small class="d-block font-12"><i class="far fa-calendar font-10 me-1" :class="fatura.pago ? 'text-green' : 'text-yellow'"></i> Data de vencimento</small>   
               </div>
               <div class="w-max-content font-16 text-center mx-auto mx-sm-0 d-flex align-items-center" v-if="!fatura.pago && fatura.urlPagamento != null">
                  <div class="btn-icone color-theme">
                     <span @click="pagar"><i class="far fa-wallet"></i><small>Pagar</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Fatura',
   props: ['fatura', 'index'],
   methods: {
      pagar : function () {
         window.open(this.fatura.urlPagamento)
      }
   }
}

</script>