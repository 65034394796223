<template>
	<div class="row align-items-start">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
			<div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
				<strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
				<span>Gerencie sua conta, meios de contato e efetue o pagamento de faturas.</span>
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
			</div>
		</div>

		<div class="nav flex-sm-column nav-pills mx-auto px-sm-0 mb-1 mb-sm-0 justify-content-center" id="config-tab" role="tablist" aria-orientation="vertical">
			<button class="nav-link mb-1 active" id="conta-tab" data-bs-toggle="pill" data-bs-target="#conta" type="button" role="tab" aria-controls="conta" aria-selected="true">
				<span class="d-flex align-items-center">
					<i class="fal fa-home mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Minha conta</span>
				</span>
			</button>
			<button class="nav-link mb-1" id="faturas-tab" data-bs-toggle="pill" data-bs-target="#faturas" type="button" role="tab" aria-controls="faturas" aria-selected="false">
				<span class="d-flex align-items-center">
					<i class="fal fa-barcode mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Faturas</span>
				</span>
			</button>
			<button class="nav-link mb-1" id="contatos-tab" data-bs-toggle="pill" data-bs-target="#contatos" type="button" role="tab" aria-controls="contatos" aria-selected="false">
				<span class="d-flex align-items-center">
					<i class="fal fa-phone-office mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Contatos</span>
				</span>
			</button>
		</div>
		
		<div class="tab-content px-sm-0" id="config-tabContent">
			<div class="tab-pane fade show active" id="conta" role="tabpanel" aria-labelledby="conta-tab">
				<div class="grid grid-custom cards-225 cards-md-350">
					<div class="card">
						<span class="icon"><i class="fal fa-wifi"></i></span>
						<h4>&nbsp;{{ modal.qtdPdvsOnline }} de {{ dadosUsuario.maxPdvsOnline }}</h4>
						<p class="text-secondary">&nbsp;Limite de caixas online</p>
						<div class="shine"></div>
						<div class="background">
							<div class="tiles">
								<div class="tile tile-1"></div><div class="tile tile-2"></div><div class="tile tile-3"></div><div class="tile tile-4"></div><div class="tile tile-5"></div>
								<div class="tile tile-6"></div><div class="tile tile-7"></div><div class="tile tile-8"></div><div class="tile tile-9"></div><div class="tile tile-10"></div>
							</div>
							<div class="line line-1"></div><div class="line line-2"></div><div class="line line-3"></div>
						</div>
					</div>
					<div class="card">
						<span class="icon"><i class="fal fa-wifi-slash"></i></span>
						<h4>&nbsp;{{ modal.qtdPdvsOffline }} de {{ dadosUsuario.maxPdvsOffline }}</h4>
						<p class="text-secondary">&nbsp;Limite de caixas offline</p>
						<div class="shine"></div>
						<div class="background">
							<div class="tiles">
								<div class="tile tile-1"></div><div class="tile tile-2"></div><div class="tile tile-3"></div><div class="tile tile-4"></div><div class="tile tile-5"></div>
								<div class="tile tile-6"></div><div class="tile tile-7"></div><div class="tile tile-8"></div><div class="tile tile-9"></div><div class="tile tile-10"></div>
							</div>
							<div class="line line-1"></div><div class="line line-2"></div><div class="line line-3"></div>
						</div>
					</div>
					<div class="card">
						<span class="icon"><i class="fal fa-store"></i></span>
						<h4>&nbsp;{{ modal.qtdLojas }} de {{ dadosUsuario.maxLojas }}</h4>
						<p class="text-secondary">&nbsp;Limite de lojas</p>
						<div class="shine"></div>
						<div class="background">
							<div class="tiles">
								<div class="tile tile-1"></div><div class="tile tile-2"></div><div class="tile tile-3"></div><div class="tile tile-4"></div><div class="tile tile-5"></div>
								<div class="tile tile-6"></div><div class="tile tile-7"></div><div class="tile tile-8"></div><div class="tile tile-9"></div><div class="tile tile-10"></div>
							</div>
							<div class="line line-1"></div><div class="line line-2"></div><div class="line line-3"></div>
						</div>
					</div>
					<div class="card">
						<span class="icon"><i class="fal fa-users"></i></span>
						<h4>&nbsp;{{ modal.qtdUsuarios }} de {{ dadosUsuario.maxUsuarios }}</h4>
						<p class="text-secondary">&nbsp;Limite de usuários</p>
						<div class="shine"></div>
						<div class="background">
							<div class="tiles">
								<div class="tile tile-1"></div><div class="tile tile-2"></div><div class="tile tile-3"></div><div class="tile tile-4"></div><div class="tile tile-5"></div>
								<div class="tile tile-6"></div><div class="tile tile-7"></div><div class="tile tile-8"></div><div class="tile tile-9"></div><div class="tile tile-10"></div>
							</div>
							<div class="line line-1"></div><div class="line line-2"></div><div class="line line-3"></div>
						</div>
					</div>
					<div class="card" v-if="dadosUsuario.dataLimiteTeste != null">
						<span class="icon"><i class="fal fa-calendar-times"></i></span>
						<h4>&nbsp;{{ String(dadosUsuario.dataLimiteTeste.dayOfMonth).padStart(2, '0') +'/'+ String(dadosUsuario.dataLimiteTeste.monthValue).padStart(2, '0') +'/'+ dadosUsuario.dataLimiteTeste.year }}</h4>
						<p class="text-secondary">&nbsp;Encerramento do teste</p>
						<div class="shine"></div>
						<div class="background">
							<div class="tiles">
								<div class="tile tile-1"></div><div class="tile tile-2"></div><div class="tile tile-3"></div><div class="tile tile-4"></div><div class="tile tile-5"></div>
								<div class="tile tile-6"></div><div class="tile tile-7"></div><div class="tile tile-8"></div><div class="tile tile-9"></div><div class="tile tile-10"></div>
							</div>
							<div class="line line-1"></div><div class="line line-2"></div><div class="line line-3"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="tab-pane fade" id="faturas" role="tabpanel" aria-labelledby="faturas-tab">
				<div class="row">
					<div class="col-12 mb-1" v-if="modal.faturas.length == 0">
						<div class="card mb-0">
							<div class="card-body py-5 text-center">
								<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
								<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhuma fatura encontrada</h4>
							</div>
						</div>
					</div>

					<fatura v-for="(fatura, index) in modal.faturas" :key="index" :fatura="fatura" :index="index" />
				</div>
			</div>
			<div class="tab-pane fade" id="contatos" role="tabpanel" aria-labelledby="contatos-tab">
				<div class="row">
					<div class="col-12 mb-1">
						<div class="card mb-0">
							<div class="card-body">
								<h6 class="mb-0 font-14 weight-400">
									<span>{{ modal.contatos.length }} contato(s) encontrado(s)</span>
									<a href="javascript:;" class="float-end font-16" data-bs-toggle="modal" data-bs-target="#modalContato"><i class="fas fa-plus color-theme"></i></a>
								</h6>
								<div class="w-100 my-5 text-center" v-if="modal.contatos.length == 0">
									<i class="fal fa-users-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
									<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Clique no ícone <i class="far fa-plus mx-1"></i> para adicionar</h4>
								</div>
							</div>
						</div>
					</div>
					
					<div class="col-12">
						<div class="cards-150 cards-sm-175 cards-lg-225 cards-xxl-250">
							<contato v-for="(contato, index) in modal.contatos" :key="index" :contato="contato" :index="index" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- modalContato -->
		<div class="modal fade" id="modalContato" tabindex="-1" aria-labelledby="modalContatoLabel" aria-hidden="true">
			<div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="modalContatoLabel">Adicionar contato</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12 pb-1">
								<div class="row">
									<div class="col-sm-4 col-md-3 mb-2">
										<label><i class="far fa-tag color-theme font-11 me-1"></i> Tipo</label>
										<v-select name="Desktop" :options="tiposContato" v-model="modal.contato.tipo" :clearable="false" :searchable="false" placeholder="Selecione uma opção">
											<template v-slot:no-options>Sem resultados</template>
										</v-select>
										<select name="Mobile" class="form-control" v-model="modal.contato.tipo">
											<option v-for="(option, index) in tiposContato" :key="index" :value="option">{{ option }}</option>
										</select>
									</div>
									<div class="col mb-2">
										<label><i class="far fa-envelope color-theme font-11 me-1"></i> Email</label>
										<input type="text" class="form-control" v-model="modal.contato.email" maxlength="200" v-focus @keyup.enter="getUsuario" placeholder="Insira o email do usuário" />
									</div>
									<div class="col-3 col-sm-2 mb-2 align-self-end">
										<button type="button" class="btn btn-primary w-100" @click="getUsuario"><i class="far fa-search font-13"></i></button>
									</div>
								</div>
							</div>
						</div>

						<div class="card mb-0" v-if="modal.contato.resultado == null || modal.contato.resultado.id == null">
							<div class="card-body py-5 text-center">
								<i class="fal fa-users-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
								<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum usuário encontrado</h4>
							</div>
						</div>

						<usuario v-else :usuario="modal.contato.resultado" :tela="'CONTATOS'" @select="addContato" />
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import fatura from '@/components/configuracoes/minhaConta/Fatura.vue'
import contato from '@/components/configuracoes/minhaConta/Contato.vue'
import usuario from '@/components/configuracoes/usuarios/Usuario.vue'

export default {
	name: 'Minha_conta',
	data: function () {
		return {
			tiposContato: ['Administrativo', 'Financeiro', 'Técnico', 'Contador'],
			modal: {
				'faturas': [], 'contatos': [], 'contato': {'email': null, 'tipo': null, 'resultado': null}, 'qtdPdvsOnline': null, 'qtdPdvsOffline': null, 'qtdLojas': null, 'qtdUsuarios': null
			}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function () {
			this.getConfiguracoes()
      }
	},
	components: {
		fatura, contato, usuario
	},
	methods: {
		getConfiguracoes : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoes/getConfiguracoes'

			}).then(response => {
				this.modal = {
					'qtdPdvsOnline': response.data.qtdPdvsOnline, 
					'qtdPdvsOffline': response.data.qtdPdvsOffline, 
					'qtdUsuarios': response.data.qtdUsuarios, 
					'qtdLojas': response.data.qtdLojas, 
					'faturas': response.data.faturas, 
					'contatos': response.data.contatos,
					'contato': {'email': null, 'tipo': this.tiposContato[0], 'resultado': null}
				}
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		getUsuario : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoes/getUsuario',
				params: {
					email: this.modal.contato.email
				}
			}).then(response => {
				this.modal.contato.resultado = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		addContato : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoes/addContato',
				headers: {'Content-Type': 'application/json'},
				data: {'idUsuario': this.modal.contato.resultado.id, 'tipo': this.modal.contato.tipo}

			}).then(response => {
				this.modal.contatos = response.data
				$('#modalContato').modal('hide')

				this.$toast.fire({
					icon: 'success',
					title: 'Contato adicionado!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
		this.getConfiguracoes()
	}
}

</script>

<style scoped>

#config-tab .nav-link i {
   font-size: 18px;
   width: 21px;
	text-align: center;
}
#config-tab .nav-link i.fa-address-book {
   transform: translateX(4px);
}
#config-tab .nav-link {
   line-height: 1;
   padding: 14px 20px;
   text-align: start;
   font-weight: 500;
   text-transform: uppercase;
   font-size: 12px;
	width: auto;
	flex: 1 0 0%;
   transition: none !important;
}
#config-tab .nav-link * {
   transition: none !important;
}
#config-tab,
#config-tabContent {
   width: 100%;
}

@media (min-width: 576px) {
   #config-tabContent {
      min-height: 210px;
      width: calc(100% - 85px)
   }
   #config-tab {
      width: 85px;
   }
}

@media (min-width: 1200px) {
   #config-tabContent {
      min-height: 210px;
      width: calc(100% - 210px)
   }
   #config-tab {
      width: 210px;
   }
}

</style>